import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const TrafficRestrictionSection = () => {
  const data = useStaticQuery(graphql`
    query {
      mockup: file(
        relativePath: { eq: "products/traffic-restriction-mockup.png" }
      ) {
        publicURL
      }
      trafficRestriction: file(
        relativePath: { eq: "products/traffic-restriction.png" }
      ) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-traffic-restriction section__left-chamfer">
      <div className="container">
        <h2 className="title has-text-white">
          Rodízio <br className="is-hidden-mobile" />
          de veículos
        </h2>
        <div className="images  is-hidden-mobile">
          <img
            className="traffic-restriction-mockup"
            src={data.mockup.publicURL}
            alt=""
          />
        </div>
        <p className="subtitle">
          Receba alertas no dia de Rodízio e evite levar uma multa por não poder
          circular.
        </p>
        <img
          className="traffic-restriction-graph"
          src={data.trafficRestriction.publicURL}
          alt="Tela de Rodízio"
        />
      </div>
    </section>
  )
}

export default TrafficRestrictionSection
